import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const InnerFooter = () => {
  return (
    <>
    <Container className='mt-5'>
     <div className="footer__botSecHolder py-3">
            <Row>
              <Col sm={6}>
                <p className="home__copyrights m-0 hebrew_font text-center text-sm-start">
                  Copyright 2024 TRADE11. Inc.
                </p>
              </Col>
              <Col sm={6} className="d-flex justify-content-center justify-content-sm-end mt-3 mt-sm-0">
                <ul className="footer__links d-flex gap-4 m-0">
                  <li className="hebrew_font mb-2"><NavLink to='/termsOfServices'>Terms of Service</NavLink></li>
                  <li className="hebrew_font mb-2"> <NavLink to='/privacyPolicy'>Privacy policy</NavLink></li>
                </ul>
                <p className="home__copyrights m-0 hebrew_font text-center text-sm-end">
                  
                </p>
              </Col>
            </Row>
          </div>
            </Container>
    </>
  )
}

export default InnerFooter