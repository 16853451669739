import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Images } from '../Components/Images'
import ScheduleModal from './ScheduleModal'

const PairModal = ({show,handleShow,handleClose}) => {

    const [showSchedule, setShowSchedule] = useState(false);

  const handleCloseSchedule = () => setShowSchedule(false);
  const handleShowSchedule = () => setShowSchedule(true);
  return (
    <>
     <Modal centered  size='sm'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body className='cmn__modal'>
          <p className='text-end m-0 modal__closer' onClick={handleClose}><i class="fa-solid fa-xmark"/></p>
          <p className='m-0 hebrew_font ticketManage__question'>Pair :</p>
          <div className='d-flex justify-content-center mt-3'>
          <div className='dashboard__singleCard'>
                    <div className='d-flex justify-content-start align-items-center gap-5'>
            <div className='opCard__topSecLeft'>
                <img src={Images.tron} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg modal__coinName mt-4 text-center text-uppercase'>Tron</p>
            </div>
            <p className='m-0 dashboard__transactAct'><i class="fa-solid fa-plus"/></p>
            <div className='opCard__topSecLeft'>
                <img src={Images.aave} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg modal__coinName mt-4 text-center text-uppercase'>aave</p>
            </div>
        </div>
       
        </div>
        </div>

        <div className='modal__singleInpFlex d-flex justify-content-start align-items-center gap-3 my-4'>
        <p className='m-0 hebrew_font ticketManage__question cmnModal_inpLabel'>Borrow :</p>
        <div className='position-relative flex-grow-1'>
        <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1' />
        </div>
        </div>

        <div className='modal__singleInpFlex d-flex justify-content-start align-items-center gap-3 mb-4'>
        <p className='m-0 hebrew_font ticketManage__question cmnModal_inpLabel'>Price :</p>
        <div className='position-relative flex-grow-1'>
            <p className='modal__inputAbs m-0 hebrew_font'>%</p>
        <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1' />
        </div>
        </div>

        <div className='modal__singleInpFlex'>
        <p className='m-0 hebrew_font ticketManage__question'>Profit expected :</p>
        <div className='position-relative'>
        <p className='modal__inputAbsDollar m-0 hebrew_font'>$</p>
        <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1 mt-2' />
        </div>
        </div>

        <div className='cmnModal__dualBtns d-flex justify-content-between mt-4'>
            <button className='primary_grad_btn rounded-5 primary_gradBtnWidth hebrew_strong' onClick={handleClose}>Confirm</button>
            <button className='secondary_btn cmnModal_secondaryBtn rounded-5 primary_gradBtnWidth hebrew_strong' data='Schedule' onClick={() => {handleShowSchedule();handleClose()}}></button>
        </div>
        </Modal.Body>
        
      </Modal>
     {/* schedule Modal */}
     <ScheduleModal show={showSchedule} handleClose={handleCloseSchedule} handleShow={handleShowSchedule} />
      {/* end of schedule Modal */}
    </>
  )
}

export default PairModal