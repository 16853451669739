import React, { useState } from 'react'
import LandingHeader from '../Components/LandingHeader'
import { Container } from 'react-bootstrap'
import Select from 'react-select';
import { Images } from '../Components/Images';
import InnerFooter from '../Components/InnerFooter';

const TicketManagement = () => {
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ];

      const [selectedOption,setSelectedOption] = useState()

      const styles = {
        option: (styles, { isFocused, isSelected, isHovered }) => ({
          ...styles,
          color: "#fff",
          background: isFocused
            ? "#5fe44b"
            : isSelected
            ? "#5fe44b"
            : isHovered
            ? "5fe44b"
            : "#5fe44b",
    
          zIndex: 1,
          cursor: "pointer",
          fontSize: "13px",
        }),
    
        option: (styles, { isFocused, isSelected, isHovered }) => {
          // const color = chroma(data.color);
    
          return {
            ...styles,
            backgroundColor: isHovered
              ? "#5fe44b"
              : isSelected
              ? "#4cd5d9"
              : isFocused
              ? "#5fe44b"
              : "#151515",
            cursor: "pointer",
            color: isHovered
              ? "#000"
              : isSelected
              ? "#000"
              : isFocused
              ? "#000"
              : "#fff",
            fontSize: "13px",
          };
        },
        indicatorSeparator: (styles) => ({ display: "none" }),
        valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "37px",
        //   border: "1px solid #e3e0e0",
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "#030c15",
          // border: "1px solid rgba(34, 34, 34, 0.32)",
          borderRadius: 5,
          fontSize: "13px",
          color: "#fff",
        }),
        control: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "40px",
        //   border: "1px solid #00CABA",
          borderRadius: 5,
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "transperant",
          // backgroundColor: "#fff",
          border: "none",
          outline: "none",
          boxShadow: "none",
          color: "#fff",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "40px",
          position: "absolute",
          right: 0,
          top: 0,
          color: "#fff",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "#fff",
        }),
        menuList: (base) => ({
          ...base,
          // kill the white space on first and last option
          padding: 0,
          borderRadius:'5px'
        }),
      };
  return (
    <>
    <Container fluid className='p-0 position-relative common_bg'>
    <img
              src={Images.radiantBlur}
              className="home__dashboardLeftBlur img-fluid"
              alt=""
            />
            <img
              src={Images.radiantBlur}
              className="home__dashboardRightBlur img-fluid"
              alt=""
            />
        <LandingHeader/>
        <Container className='home__indexAligner'>
        <div className='plans__holder'>
        <h4 className='m-0 hebrew_bold opportunity__title'>Ticket Management</h4>
        <div className='dashboard__blueBox p-4 rounded-2 h-100 mt-5'>
                <h4 className='m-0 hebrew_bold dashboard__midGrad'>Trade 11 Stats</h4>
<div className='ticketManage__quizHolder mt-4'>
                <div className='ticketManage__singleQuiz mb-3'>
                    <p className='m-0 hebrew_font ticketManage__question'>1. Enter the Transaction hash</p>

                    <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1 mt-2' />
                </div>

                <div className='ticketManage__singleQuiz mb-3'>
                    <p className='m-0 hebrew_font ticketManage__question'>1. Enter the Transaction hash</p>

                    <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1 mt-2' />
                </div>

                <div className='ticketManage__singleQuiz mb-3'>
                    <p className='m-0 hebrew_font ticketManage__question'>2. Enter the severity type</p>

                    <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e)}
        options={options}
        styles={styles}
      />
                </div>

                <div className='ticketManage__singleQuiz mb-3'>
                    <p className='m-0 hebrew_font ticketManage__question'>3. Subject</p>

                    <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1 mt-2' />
                </div>

                <div className='ticketManage__singleQuiz mb-3'>
                    <p className='m-0 hebrew_font ticketManage__question'>4. Describe your issue in detail</p>

                    <textarea className='ticketManage__input hebrew_font w-100 rounded-1 mt-2' rows="5" cols="50"></textarea>
                </div>

                <div className='text-center mt-5'>
                            <button className='primary_grad_btn primary_gradBtnWidth rounded-5'>Submit</button>
                        </div>
                </div>
                </div>
        </div>
        </Container>
        <InnerFooter/>
    </Container>
    </>
  )
}

export default TicketManagement