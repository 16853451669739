import React from 'react'

const PlansCard = (props) => {
  return (
    <>
    <div className='plansCard px-3 py-4'>
    <div className='plansCard__top d-flex justify-content-between align-items-start'>
        <div>
        <h6 className='m-0 hebrew_bold plansCard__status'>{props.data.planName}</h6>
        <p className='m-0 hebrew_font plansCard__hint mt-2'>Portfolio: up to ${props.data.portfolio}K</p>
        </div>
        <h6 className='m-0 hebrew_bold plansCard__statusSymbol'>'''''</h6>
    </div>
    <p className='m-0 hebrew_font plansCard__desc mt-4 w-75'>For individuals getting started with portfolio management</p>

    <h4 className='m-0 hebrew_bold plansCard__price mt-5'>${props.data.price} per month</h4>
    <p className='m-0 hebrew_font plansCard__hint mt-2'>with annual payment</p>
    <button className='secondary_btn hebrew_strong rounded-2 mt-3' data='Choose plan'></button>
    </div>
    </>
  )
}

export default PlansCard