import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import LandingHeader from '../Components/LandingHeader'
import OpportunityCard from '../Components/OpportunityCard'
import { allData } from '../Components/CommonData'
import { Images } from '../Components/Images'
import InnerFooter from '../Components/InnerFooter'

const Opportunities = () => {
  return (
    <>
    <Container fluid className='p-0 position-relative common_bg'>
    <img
              src={Images.radiantBlur}
              className="home__dashboardLeftBlur img-fluid"
              alt=""
            />
            <img
              src={Images.radiantBlur}
              className="home__dashboardRightBlur img-fluid"
              alt=""
            />
    <LandingHeader />
    <Container className='home__indexAligner innerpages_ui'>
        <div className='plans__holder'>
            <h4 className='m-0 hebrew_bold opportunity__title'>Market Opportunities</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>Create Your Account to Gain Access to Our Arbitrage and Career Earning Opportunities</p>

            <Row className='mt-4'>
{allData.opportunityCard.map((item) =>
                <Col lg={3} md={4} sm={6} className='mb-4'>
                    <OpportunityCard data={item} />
                </Col>
                 )}
            </Row>
        </div>
    </Container>
    <InnerFooter/>
    </Container>
    </>
  )
}

export default Opportunities