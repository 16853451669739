import React from 'react'

const BenefitCard = (props) => {
    console.log(props,"safdsafas");
  return (
    <>
    <div className='home__serviceCard rounded-4 p-4 d-flex flex-column justify-content-center align-items-center'>
    <img src={props.data.img} alt='' className='img-fluid home__serviceCardImg' />
    {/* <h6 className='m-0 hebrew_bold home__serviceCardTitle text-center my-4'>{props.data.title}</h6> */}
    <p className='m-0 hebrew_font home__serviceCardHint text-center mt-4'>{props.data.hint}</p>
    </div>
    </>
  )
}

export default BenefitCard