import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import LandingHeader from '../Components/LandingHeader'
import {  NavLink } from 'react-router-dom'
import InnerFooter from '../Components/InnerFooter'

const TermsOfServices = () => {
    useEffect(() => {
        window.scroll(0,0)
    },[])
  return (
    <>
    <Container fluid className='p-0 position-relative common_bg'>
        <LandingHeader/>
        <Container className='home__indexAligner'>
        <div className='plans__holder'>
        <h4 className='m-0 hebrew_bold opportunity__title'>Terms of Use</h4>
        <h4 className='m-0 hebrew_bold dashboard__midGrad mt-4'>Preamble</h4>
           

            <div className='dashboard__blueBox p-4 pt-0 rounded-2 mt-4'>
            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>1. Terms of Service</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>Trade11 (hereafter also “We”) offers online software as a service (SaaS) through the website <NavLink target='_blank' to='https://trade11.io'>https://trade11.io</NavLink> (hereafter – “Website”). Our software (hereafter – “Software”) enables you to trade in cryptocurrencies through flash loan arbitrage by means of an automatic crypto trader bot – of which (solely) you control and configure the settings. These terms and conditions (hereafter – “Terms”) apply to the relationship between Trade11 and Users (hereafter – “you”) for any use of the Website and the Software that Trade11 offers. It is prohibited to use the Website or Software without accepting these Terms.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>2. Definitions</h4>
            <ul className='terms__ulList m-0 mt-2'>
                <li className='hebrew_font terms__list plans__titleHint'>Company: Trade11</li>
                <li className='hebrew_font terms__list plans__titleHint'>User(s): an individual private person(s) or legal entity making use of the Website or Software.</li>
                <li className='hebrew_font terms__list plans__titleHint'>Software: the software developed by Trade11 in order to enable Users to trade in crypto currencies on the crypto market, in the form of a crypto trading tool / bot (hereafter – “bot”). The Software is available through the Website <NavLink target='_blank' to='https://trade11.io'>https://trade11.io</NavLink></li>
            </ul>


            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>3. Registration and personal account</h4>
            <ul className='terms__ulList m-0 mt-2'>
                <li className='hebrew_font terms__list plans__titleHint'>If you want to use our Website and Software, go to <NavLink target='_blank' to='https://trade11.io'>https://trade11.io</NavLink> You will need to register and create a personal account in order to use the Website and Software.</li>
                <li className='hebrew_font terms__list plans__titleHint'>You must protect the login details of your account and keep your password strictly secret. We will assume that all actions taken from your account are done by you or on your behalf by the authorized person.</li>
                <li className='hebrew_font terms__list plans__titleHint'>You agree to provide up-to-date, complete and accurate information on your account. You agree to promptly update your personal account when necessary, so that we can contact you as the case may be.</li>
            </ul>

            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>4. General Information</h4>
            <ul className='terms__ulList m-0 mt-2'>
                <li className='hebrew_font terms__list plans__titleHint'>4.1 Software and Website can be used with a paid subscription</li>
                <li className='hebrew_font terms__list plans__titleHint'>4.2 We reserve the right to change the prices going forward, however we will never change the prices retroactively.</li>
                <li className='hebrew_font terms__list plans__titleHint'>4.3 If you decide not to prolong your subscription, all will be deactivated automatically when the subscription expires and may be deleted after 24 hours of inactivity. If the bots are deactivated for more than 24 hours on a free trial, Trade11 reserves the right to delete them.</li>
                <li className='hebrew_font terms__list plans__titleHint'>4.4 Regardless of the type of subscription, the Software and Website are always subject to these Terms.</li>
                <li className='hebrew_font terms__list plans__titleHint'>4.5 We do not bear any responsibility for fees and commissions connected to your participation in crypto trading, and/or that may be charged by the third parties</li>
            </ul>


            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>5. Fair use of our Website and Software</h4>
            <ul className='terms__ulList m-0 mt-2'>
                <li className='hebrew_font terms__list plans__titleHint'>By using our Website and Software, you declare to be at least 18 years old.</li>
                <li className='hebrew_font terms__list plans__titleHint'>You may not use the Website and Software in such a way that you violate the law or any other applicable laws and regulations.</li>
                <li className='hebrew_font terms__list plans__titleHint'>As a condition for using the Website and Software, you agree not to provide any information, data or content to us or the Website and Software that is incorrect, inaccurate, incomplete or that violates any law or regulation. In addition, you represent that you will not, nor allow third parties over whom you have or may have immediate control to:</li>
                <li className='hebrew_font terms__list plans__titleHint'>Enter any non-public / secure areas of the Website or Software;</li>
                <li className='hebrew_font terms__list plans__titleHint'>Send viruses, worms, junk mail, spam, chain letters, unsolicited offers or ads of any kind and for any purpose;</li>
                <li className='hebrew_font terms__list plans__titleHint'>Investigate, scan or test the Website of Software or any other related system or network, or violate any security or authentication;</li>
                <li className='hebrew_font terms__list plans__titleHint'>Use any automated systems of software to withdraw data from the Website (“screen-scraping”);</li>
                <li className='hebrew_font terms__list plans__titleHint'>Make and distribute copies of the Website or Software;</li>
                <li className='hebrew_font terms__list plans__titleHint'>Attempt to sell, distribute, copy, rent, sub-license, loan, merge, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange, translate, hack, distribute, harm or misuse the Website or Software;</li>
                <li className='hebrew_font terms__list plans__titleHint'>Create derivative works of any kind whatsoever.</li>
                <li className='hebrew_font terms__list plans__titleHint'>You may not create an account under someone else’s name or act like someone else in any other way (i.e. commit “identity theft”).</li>
                <li className='hebrew_font terms__list plans__titleHint'>In case the account concerns a corporate account, only an authorized person is allowed to trade with the corporate account. It is the responsibility of the user of the corporate account that only authorized persons have access to the account.</li>
                <li className='hebrew_font terms__list plans__titleHint'>Trade11 is entitled to (temporarily or permanently) block your account and deny you access to the Website, if we suspect abuse of the account or the Website. We can also block your account or deny you access to the platform if you do not comply with these Terms, including conditions and policies referenced herein.</li>
            </ul>

            
            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>6. Privacy</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>We strictly follow industry best practices and adhere to the rules set forth in the General Data Protection Regulation, OPPA, CAN-SPAM and COPPA. When you make use of our Website and Software, we will collect certain personal data from you. In our Privacy Policy you can read which personal data we collect and for what purposes. The Privacy Policy is an integral part of this Terms. You can find our privacy policy here: <NavLink target='_blank' to='https://trade11.io/en/privacy'>https://trade11.io/en/privacy</NavLink></p>


            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>7. Intellectual Property</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>Please note that content, materials, information, and functionality available on the Website and the Software are protected in all WTO-member states. Trade11 is the exclusive licensee of all intellectual property rights vesting in and relating to (all content made available through) the Website and the Software, such as – but not limited to – patents, patent applications, trademarks, trademark applications, database rights, service marks, trade names, copyrights, trade secrets, licenses, domain names, know-how, property rights and processes (“Intellectual Property Rights”).</p>

            <ul className='terms__ulList m-0 mt-2'>
                <li className='hebrew_font terms__list plans__titleHint'>Availability of the Website and Software and disclaimer of warranties</li>
                <li className='hebrew_font terms__list plans__titleHint'>a. Trade11 grants its Users a non-transferrable, non-exclusive, non-sublicensable and revocable license of the Website and Software as offered by us at <NavLink target='_blank' to=' https://Trade11.io'> https://Trade11.io</NavLink> subject to limitations outlined in clause 5.</li>
                <li className='hebrew_font terms__list plans__titleHint'>b. Any other use of the Services or Content is expressly prohibited. All other rights in the Platform or Content are reserved by us and our licensors. You will not otherwise copy, transmit, distribute, sell, resell, license, de-compile, reverse engineer, disassemble, modify, publish, participate in the transfer or sale of, create derivative works from, perform, display, incorporate into another website, or in any other way exploit any of the Content or any other part of the Services in whole or in part for commercial or non-commercial purposes. If you violate any portion of the license, your permission to access and use the Platform will be terminated immediately. We reserve the right to seek legal remedies available in the applicable law in the case of such a violation.</li>
                <li className='hebrew_font terms__list plans__titleHint'>c. Trade11 will use reasonable efforts to make the Website and Software available at all times. However, User acknowledges that the Website and Software are provided over the internet and mobile networks and thus the quality and availability of the Website and Software may be affected by factors outside Trade11’s reasonable control.</li>
                <li className='hebrew_font terms__list plans__titleHint'>d. Trade11 does not accept any responsibility whatsoever for unavailability of the Website and Software, or any difficulty or inability to download or access content, or any other communication system failure which may result in the Website or Software being unavailable (hereinafter - Outages). However, In case of Outages we will prolong your paid subscription for the number of full days the Services were unavailable due to the Outage.</li>
                <li className='hebrew_font terms__list plans__titleHint'>e. Trade11 is not responsible for any support or maintenance regarding the Website or Software. Trade11 may – at its own discretion – update, modify, or adapt the Website or Software and their functionalities from time to time to enhance the user experience. Trade11 is not responsible for any downtime resulting from these actions.</li>
                <li className='hebrew_font terms__list plans__titleHint'>f. To the maximum extent permitted by applicable law, Trade11 hereby disclaims all implied warranties regarding the availability of the Website and Software. The Website and Software are provided "as is" and "as available" without warranty of any kind.</li>
                </ul>


                <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>8. Helpdesk, advice and disclaimer</h4>

            <ul className='terms__ulList m-0 mt-2'>
                <li className='hebrew_font terms__list plans__titleHint'>Trade11 has a helpdesk where User can ask questions about the Website and Software. Trade11 will only give advice about the functioning of the Website and Software.</li>

                <li className='hebrew_font terms__list plans__titleHint'>Trade11 explicitly does not:</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>a. Give Users any personal advice on recommended settings for the Bots;</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>b. Give Users any personal financial advice.</li>
                <li className='hebrew_font terms__list plans__titleHint'>Trade11 may upload general tutorials and academy videos on the Website, about the functioning of the Website and Software.</li>
                <li className='hebrew_font terms__list plans__titleHint'>All tutorials, videos and templates uploaded by Trade11 are general and contain in no way personal and/or financial advice. All use of these advice is at the sole risk of the User.</li>
                <li className='hebrew_font terms__list plans__titleHint'>Users may receive notifications about the transaction made in their Trade11 account by using Telegram bot. However, Trade11 assumes no responsibility over the information received through above-mentioned service, since its functionality and availability lies beyond our reasonable control.</li>
                </ul>


                <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>9. Risks and legal advice</h4>

<ul className='terms__ulList m-0 mt-2'>
    <li className='hebrew_font terms__list plans__titleHint'>The User is aware of the accompanying risks of possessing, trading and using crypto currencies and takes full responsibility for these risks.</li>

    <li className='hebrew_font terms__list plans__titleHint'>No information provided by us shall be interpreted to constitute legal advice. You should bear in mind that the laws of your state of residence may prohibit transactions made with cryptocurrencies. In case you are uncertain of the status of cryptocurrencies in your jurisdiction, you should seek legal advice from a legal professional practicing law in your jurisdiction.</li>
    
    </ul>


    <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>10. Liability</h4>

<ul className='terms__ulList m-0 mt-2'>
    <li className='hebrew_font terms__list plans__titleHint'>Nothing in these Terms shall exclude or limit Trade11’s liability when it cannot be excluded or limited under applicable law.</li>

    <li className='hebrew_font terms__list plans__titleHint'>Trade11 is not liable to you for any (direct or indirect) damage you suffer as a result of the use of the Website or Software or the content provided thereon. For example, Trade11 is not liable for:</li>

    <li className='hebrew_font terms__list plans__titleHint ms-4'>1. The proper functioning of (hyper) links provided by the Website or Software;</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>2. The (lack of) financial benefit for the Users through the use of the Website or Software;</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>3. Any situation where Users login details and/or password is stolen and any third party subsequently makes use of the Website or Software without User’s consent;</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>4. Any damage or alteration to User’s equipment including but not limited to computer equipment or a handheld device as a result of the use of the Website or Software;</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>5. A failure to meet any of Trade11’s obligations under these Terms where such failure is due to events beyond Trade11’s reasonable control.</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>Indemnification</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>6. Users will indemnify, defend, and hold Trade11 harmless from and against all liabilities, damages and costs (including settlement costs and reasonable attorneys’ fees) arising out of third-party claims regarding:</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>i. Any injury or damages resulting from the behavior of User related to the use of our Website and Software; and</li>
                <li className='hebrew_font terms__list plans__titleHint ms-4'>ii. Breach by User of these Terms or violation of any applicable law, regulation or order.</li>
    
    </ul>


    <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>11. Miscellaneous</h4>

<ul className='terms__ulList m-0 mt-2'>
    <li className='hebrew_font terms__list plans__titleHint'>Trade11 reserves the right to change these Terms. When we change these Terms in a significant way, we will notify Users by newsletter (if User has provided us with his e-mail address to this end) and post a notification on our website along with the updated Terms. By continuing to use the Website, you acknowledge the most recent version of these Terms.</li>

    <li className='hebrew_font terms__list plans__titleHint'> If we do not enforce (parts of) these Terms, this cannot be construed as consent or waiver of the right to enforce them at a later moment in time or against another User.</li>
    <li className='hebrew_font terms__list plans__titleHint'> The User cannot transfer the rights and obligations from these Terms to third parties.</li>
    
    </ul>

    <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>12. Links</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>Users agree that Trade11 takes no responsibility for other Sites or other resources offered by a third party over our Site. Trade11 takes no responsibility for the contents, ads, projects or other resources on the Sites mentioned above. Trade11 takes no responsibility for the commodities, services or information on the above-mentioned Sites</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>13. Severability Entire agreement</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>These Terms constitute the entire agreement between you and us. These Terms supersede all prior communications, contracts, or agreements between the parties concerning the subject matter addressed in these Terms, whether oral or written.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>14. Force majeure</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>In addition to other disclaimers, our performance under these Terms shall be excused in the event of interruption and/or delay due to, or resulting from, causes beyond our reasonable control, including but not limited to acts of God, acts of any government, war or other hostility, civil disorder, the elements, fire, flood, snowstorm, earthquake, explosion, embargo, acts of terrorism, power failure, equipment failure, industrial or labor disputes or controversies, acts of any third party data provider(s) or other third-party information provider(s), third-party software, or communication method interruptions.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>15. Complaints, comments and suggestions</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>Trade11 strives to give you the optimal service. If you have a complaint, comment or suggestion, you can contact us at <NavLink target='_blank' to='https://trade11.io'>https://trade11.io</NavLink> Please provide us with your contact details, and a clear description and reason for your complaint. Complaints are usually processed within 30 working days.
The invalidity or unenforceability of any provision of these Terms shall not affect the validity or enforceability of any other provision of these Terms. Any such invalid or unenforceable provision shall be replaced or be deemed to be replaced by a provision that is considered to be valid and enforceable and which interpretation shall be as close as possible to the intent of the invalid provision.
</p>

<p className='plans__titleHint m-0 hebrew_font position-relative mt-2'><strong className='hebrew_strong'>Disclaimer:</strong> We are not a registered broker-dealer or an investment advisor. You must trade and take sole responsibility to evaluate all information provided by this website and use it at your own risk. Investments in securities, commodities, currencies and other investment options are speculative and involve high degrees of risk. You could lose all or a substantial amount of your investment. You should carefully read all related information regarding any investment, and consult with your advisors, before investing.
</p>

<h4 className='m-0 hebrew_bold dashboard__midGrad pt-4'>Right to be forgotten</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>If you want to close your account and delete all information that would allow anyone to identify you, please write to us at hello@trade11.io. Please note that such a request will also terminate your Trade11 account permanently. . No refund policy allowed as per terms as assets / funds stored on your account only.

</p>
            </div>
            </div>
        </Container>
        <InnerFooter/>
    </Container>
    </>
  )
}

export default TermsOfServices