import React, { useState } from 'react'
import { Images } from '../Components/Images';
import { Modal } from 'react-bootstrap';
import Web3 from 'web3';

const ConnectWallet = ({show,handleClose}) => {
    const [accounts, setAccounts] = useState([]);

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const web3 = new Web3(window.ethereum);
                const accs = await web3.eth.getAccounts();
                setAccounts(accs);
            } catch (error) {
                console.error('Error connecting to Wallet:', error);
            }
        } else {
            console.error('Wallet not detected');
        }
    };
  return (
    <>
<Modal centered  size='sm'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body className='cmn__modal'>
          <p className='text-end m-0 modal__closer' onClick={handleClose}><i class="fa-solid fa-xmark"/></p>
          <p className='m-0 hebrew_font ticketManage__question'>Select Wallet :</p>
          <div className='d-flex justify-content-between mt-4 mb-4'>
            <div className='cnctWallet__singleBox d-flex flex-column justify-content-center align-items-center'  onClick={connectWallet} >
          <img src={Images.metamask} className='img-fluid opCard__coinImg' alt='' />
          <p className='m-0 hebrew_font ticketManage__question mt-2 text-center'>Metamask</p>
          </div>
          <div className='cnctWallet__singleBox d-flex flex-column justify-content-center align-items-center'>
          <img src={Images.trustWallet} className='img-fluid opCard__coinImg' alt='' />
          <p className='m-0 hebrew_font ticketManage__question mt-2 text-center'>Connect Wallet</p>
          </div>
          <div className='cnctWallet__singleBox d-flex flex-column justify-content-center align-items-center'>
          <img src={Images.walletConnect} className='img-fluid opCard__coinImg' alt='' />
          <p className='m-0 hebrew_font ticketManage__question mt-2 text-center'>Trust Wallet</p>
          </div>
                   
        </div>

       

      
        </Modal.Body>
        
      </Modal>
    </>
  )
}

export default ConnectWallet