import { Images } from "./Images";

export const allData = {
    serviceCard : [
        {
            count : '01',
            title : 'Zero Collateral',
            hint : 'Get Flash Loans instantly on Whitelisted Licenses. No Security / No Collateral required.'
        },
        {
            count : '02',
            title : 'AI & API Driven',
            hint : 'Advanced AI based technology and Robust API’s driven platform.'
        },
        {
            count : '03',
            title : 'Rapid Servers',
            hint : 'Located close to popular exchanges to ensure stable and fast orders execution.'
        },
        {
            count : '04',
            title : '100% Secure',
            hint : 'We use Fast Connect and IP whitelisting to provide strong security, ensure safety and security.'
        },
        {
            count : '05',
            title : 'AI Analytics',
            hint : 'Our AI shows how your trades are performing, so you know when to optimize and when to let them run.'
        },
        {
            count : '06',
            title : '24x7 Automation',
            hint : 'The market never sleeps, and neither do our AI Tech'
        },
    ],
    benefitCard : [
        {
            img : Images.orangeGraph,
            title : 'Data Analytics',
            hint : 'Zero Collateral Flash Loan. Get Loan on Every Transaction'
        },
        {
            img : Images.blueGraph,
            title : 'Training And Workshops',
            hint : 'Flash Loan Tech Protocols are too Expensive! We Simplified! '
        },
        {
            img : Images.pinkGraph,
            title : 'Custom AI Design',
            hint : 'Our AI DEX Scan Every DEX’s & Present Best Trade Pairs'
        },
        {
            img : Images.orangeGraph,
            title : 'AI Integration',
            hint : 'User Friendly UI / UX'
        },
        {
            img : Images.blueGraph,
            title : 'Custom AI Development',
            hint : 'No Experience / Trading Knowledge need. Our AI do all'
        },
        {
            img : Images.pinkGraph,
            title : 'AI Consulting',
            hint : 'Our AI executes, Only profitable trades. No question of trade with loss!'
        },
    ],
    accordionData : [
        {
            question : 'Lorem Ipsum is simply dummy text',
            answer : 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of etters, as opposed to using "Content here, content here", making it look like readable English.'
        },
        {
            question : 'Lorem Ipsum is simply dummy text',
            answer : 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of etters, as opposed to using "Content here, content here", making it look like readable English.'
        },
        {
            question : 'Lorem Ipsum is simply dummy text',
            answer : 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of etters, as opposed to using "Content here, content here", making it look like readable English.'
        },
       
    ],
    blogCard: [
        {
            img : Images.blog1,
            title : 'A Journey into the heart of Innovation',
            hint : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            img : Images.blog2,
            title : 'A Journey into the heart of Innovation',
            hint : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            img : Images.blog3,
            title : 'A Journey into the heart of Innovation',
            hint : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            img : Images.blog4,
            title : 'A Journey into the heart of Innovation',
            hint : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
    ],
    planCard : [
        {
            planName : 'Starter',
            portfolio : '3.5',
            price : 20
        },
        {
            planName : 'Plus',
            portfolio : '3.5',
            price : 25
        },
        {
            planName : 'Premium',
            portfolio : '3.5',
            price : 50
        },
        {
            planName : 'Starter',
            portfolio : '3.5',
            price : 20
        },
    ],
    opportunityCard : [
        {
            leftImg : Images.tron,
            leftCoinName : 'tron',
            rightImg : Images.weth,
            rightCoinName : 'weth',
            profilt : 0.6111
        },
        {
            leftImg : Images.usdc,
            leftCoinName : 'usdc',
            rightImg : Images.aave,
            rightCoinName : 'aave',
            profilt : 0.6111
        },
        {
            leftImg : Images.link,
            leftCoinName : 'link',
            rightImg : Images.rndr,
            rightCoinName : 'rndr',
            profilt : 0.6111
        },
        {
            leftImg : Images.usdc,
            leftCoinName : 'usdc',
            rightImg : Images.matic,
            rightCoinName : 'matic',
            profilt : 0.6111
        },
        {
            leftImg : Images.aave,
            leftCoinName : 'aave',
            rightImg : Images.rndr,
            rightCoinName : 'rndr',
            profilt : 0.6111
        },
        {
            leftImg : Images.tron,
            leftCoinName : 'tron',
            rightImg : Images.weth,
            rightCoinName : 'weth',
            profilt : 0.6111
        },
        {
            leftImg : Images.usdc,
            leftCoinName : 'usdc',
            rightImg : Images.aave,
            rightCoinName : 'aave',
            profilt : 0.6111
        },
        {
            leftImg : Images.link,
            leftCoinName : 'link',
            rightImg : Images.rndr,
            rightCoinName : 'rndr',
            profilt : 0.6111
        },
        {
            leftImg : Images.usdc,
            leftCoinName : 'usdc',
            rightImg : Images.matic,
            rightCoinName : 'matic',
            profilt : 0.6111
        },
        {
            leftImg : Images.aave,
            leftCoinName : 'aave',
            rightImg : Images.rndr,
            rightCoinName : 'rndr',
            profilt : 0.6111
        },
        {
            leftImg : Images.tron,
            leftCoinName : 'tron',
            rightImg : Images.weth,
            rightCoinName : 'weth',
            profilt : 0.6111
        },
        {
            leftImg : Images.usdc,
            leftCoinName : 'usdc',
            rightImg : Images.aave,
            rightCoinName : 'aave',
            profilt : 0.6111
        }
    ],
    reviewCard : [
        {
            cont : `"Choosing this AI company was the best decision for my business. Their customized solutions have streamlined our processes, providing insights that were previously unimaginable. The impact on productivity and decision-making has been remarkable. Highly recommended!"`,
            name : 'Sarah Leon',
            designation : 'Business Owner, Dubai'
        },
        {
            cont : `"Incredible innovation! This AI company has not only exceeded my expectations but has redefined what's possible in the tech world. Their cutting-edge solutions seamlessly integrate into our workflows, bringing efficiency intelligence to the forefront. Truly a game-changer!"`,
            name : 'Adam Zampol',
            designation : 'CEO Kingoston Corps, USA'
        },
        {
            cont : `"From an IT perspective, seamless implementation and minimal troubleshooting are signs of a quality product. This AI company not only delivers cutting-edge solutions but also ensures that end-users experience a level of satisfaction that is second to none."`,
            name : 'Michael Hamilton',
            designation : 'CEO, Piqo Tech, Indonesia'
        },
    ],
    verticalCard : [
        {
            img : Images.iq,
            title : '100% safe & security',
            hint : 'We never ask / can’t access your assets/funds like other platforms'
        },
        {
            img : Images.shield,
            title : 'Trust & Transperant',
            hint : 'Count on years of market expertise & No hidden terms we applied.'
        },
        {
            img : Images.seamless,
            title : 'Seamless Interphase',
            hint : 'Effortless navigation ensures a user friendly, intuitive experience.'
        },
        {
            img : Images.technical,
            title : 'Technical Analytics',
            hint : 'Access BUY/SELL signals using Industry best & AI technical analysis indicators'
        },
        {
            img : Images.dedicated,
            title : 'Dedicated Support',
            hint : 'Our Team assists in different verticals for prompt issue resolutions.'
        },
        {
            img : Images.visionary,
            title : 'Visionary Leadership',
            hint : `Our passionate team aspires to transform every trader's journey successfully.`
        },

    ]
}