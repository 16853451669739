import React from 'react'
import { Container } from 'react-bootstrap'
import LandingHeader from '../Components/LandingHeader'
import ReactDatatable from '@ashvin27/react-datatable';
import { Images } from '../Components/Images';
import InnerFooter from '../Components/InnerFooter';

const TransactionHistory = () => {

    const state = {
        records: [
          {
            'sno': <p className='exchange_table_values m-0 text-center'>1</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>2</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>1</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>2</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>1</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>2</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>1</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>2</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>1</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>2</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>1</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },
          {
            'sno': <p className='exchange_table_values m-0 text-center'>2</p>,
            "transactionHash": <p className='exchange_table_values m-0 text-center'>#123</p>,
            "borrowAmount": <p className='exchange_table_values m-0 text-center'>10</p>,
            "pairs": <p className='exchange_table_values m-0 text-center'>BTC-ETH</p>,
            "profitAmount" : <p className='exchange_table_values m-0 text-center'>5</p>,
            "profitPercentage" : <p className='exchange_table_values m-0 text-center'>2%</p>,
            "profitStatus" : <p className='exchange_table_values m-0 text-center'>Active</p>,
            "transactionStatus" : <p className='exchange_table_values m-0 text-center'>Completed</p>    
          },

          
        ]
    }
    
    const columns = [
      {
        key: "sno",
        text: "S.No",
        className: "sno",
        align: "center",
        sortable: false,
    },
        {
            key: "transactionHash",
            text: "Transaction Hash",
            className: "transactionHash",
            align: "center",
            sortable: false,
            
        },
        {
            key: "borrowAmount",
            text: "Borrow Amount",
            className: "borrowAmount",
            align: "center",
            sortable: false,
            
        },
        {
            key: "pairs",
            text: "Pairs",
            className: "pairs",
            align: "center",
            sortable: false,
            
        },
        {
            key: "profitAmount",
            text: "Profit Amount",
            className: "profitAmount",
            align: "center",
            sortable: false,
            
        },
        {
            key: "profitPercentage",
            text: "Profit Percentage",
            className: "profitPercentage",
            align: "center",
            sortable: false,
            
        },
        {
            key: "profitStatus",
            text: "Profit Status",
            className: "profitStatus",
            align: "center",
            sortable: false,
            
        },
        {
            key: "transactionStatus",
            text: "Transaction Status",
            className: "transactionStatus",
            align: "center",
            sortable: false,
            
        },
       
    ];
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Data found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: false,
    };

    const extraButtons =[
        {
            className:"btn btn-primary buttons-pdf",
            title:"Export TEst",
            children:[
                <span>
                <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick:(event)=>{
                console.log(event);
            },
        },
        {
            className:"btn btn-primary buttons-pdf",
            title:"Export TEst",
            children:[
                <span>
                <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick:(event)=>{
                console.log(event);
            },
            onDoubleClick:(event)=>{
                console.log("doubleClick")
            }
        },
    ]
  return (
   <>
   <Container fluid className='p-0 position-relative common_bg'>
   <img
              src={Images.radiantBlur}
              className="home__dashboardLeftBlur img-fluid"
              alt=""
            />
            <img
              src={Images.radiantBlur}
              className="home__dashboardRightBlur img-fluid"
              alt=""
            />
    <LandingHeader/>
    <Container className='home__indexAligner innerpages_ui' >
    <div className='plans__holder'>
    <input type='text' className='transacHistory__input hebrew_font w-100 rounded-1 mt-2' placeholder='Search with TXN Hash'/>
    <div className='dashboard__blueBox rounded-2 mt-5'>
    <div className='transacHistory__tableHolder'>
    <ReactDatatable 
                    config={config}
                    records={state.records}
                    columns={columns}
                />
    </div>
    </div>
    </div>
    </Container>
    <InnerFooter/>
   </Container>
   </>
  )
}

export default TransactionHistory