import React, { useRef } from 'react'
import LandingHeader from '../Components/LandingHeader'
import { Col, Container, NavLink, Row } from 'react-bootstrap'
import PlansCard from '../Components/PlansCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { Images } from '../Components/Images';
import { allData } from '../Components/CommonData';
import InnerFooter from '../Components/InnerFooter';

const Plans = () => {
    const swiperRef = useRef(null);
    const goNext = () => {
        if(swiperRef.current && swiperRef.current.swiper) swiperRef.current.swiper.slideNext()
    }
const goPrev = () => {
    if(swiperRef.current && swiperRef.current.swiper)swiperRef.current.swiper.slidePrev()
}
  return (
   <>
   <Container fluid className='p-0 position-relative common_bg'>
    <img src={Images.middleBlueBlue} className='plans__middleBlur img-fluid' />
    <img src={Images.plansRightBlur} className='plans__rightBlur img-fluid' />
    <LandingHeader/>
    <Container className='home__indexAligner innerpages_ui'>
        <div className='plans__holder'>
            <h4 className='plans__title text-uppercase hebrew_bold pt-4 text-center position-relative'>plans</h4>
            <p className='plans__titleHint m-0 hebrew_font text-center position-relative'>At Altranz, we envision a future where trading is not just about insights</p>
        </div>

        <div className='plans__borderBox mt-4 p-4 rounded-4'>
        <h4 className='plans__boxTitle text-uppercase hebrew_bold text-center position-relative' >Choose your plan</h4>
        <p className='plans__titleHint m-0 hebrew_font text-center position-relative'>At Altranz, we envision a future where trading is not just about insights</p>
        <Row className='mt-5 justify-content-center position-relative'>
        <button className='plans__swiperPrevBtn rounded-5' onClick={goPrev}><img src={Images.swiperIcon}/></button>
      <button className='plans__swiperNextBtn rounded-5' onClick={goNext}><img src={Images.swiperIcon}/></button>
            <Col xs={12} sm={11}>
            <Swiper ref={swiperRef} className="mySwiper plans__swiper" navigation={true} modules={[Navigation]} autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // loop={true}
        breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
          }} >
            {allData?.planCard?.map((item) => 
        <SwiperSlide><PlansCard data={item} /></SwiperSlide>
        )}
        
        
      </Swiper>

      
            </Col>
        </Row>

        </div>

        <NavLink><p className='plans__skipper m-0 hebrew_font text-center my-5'>I will do it later<i class="fa-solid fa-chevron-right ms-2"/></p></NavLink>
        
    </Container>
    <InnerFooter/>
   </Container>
   </>
  )
}

export default Plans