import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Images } from "./Images";
import { NavLink } from "react-router-dom";
import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoInstagram } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { BiLogoLinkedin } from "react-icons/bi";
import { FaYoutube } from "react-icons/fa";
import { BsMedium } from "react-icons/bs";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

const Footer = () => {
  return (
    <>
      <Container>
        <div className="mt-5">
          <div className="footer__topSecHolder py-3">
            <Row className="justify-content-between">
              <Col md={4} sm={6} className="mb-4 mb-md-0">
                <img
                  src={Images.logo}
                  className="img-fluid footer__logo"
                  alt=""
                />
                <p className="m-0 hebrew_font footer__desc">
                  Trade11 is a cloud platform, empowering traders to automate
                  their trading on major exchanges.
                </p>
                <div className="footer__socialLinksHolder d-flex justify-content-start align-items-center gap-2 mt-3">
                  <button className="footer__socialLink rounded-5 d-flex justify-content-center align-items-center">
                    <BiLogoFacebook />
                  </button>
                  <button className="footer__socialLink rounded-5 d-flex justify-content-center align-items-center">
                    <FaXTwitter />
                  </button>
                  <button className="footer__socialLink rounded-5 d-flex justify-content-center align-items-center">
                    <BiLogoInstagram />
                  </button>
                  <button className="footer__socialLink rounded-5 d-flex justify-content-center align-items-center">
                    <BiLogoLinkedin />
                  </button>
                  <button className="footer__socialLink rounded-5 d-flex justify-content-center align-items-center">
                    <FaYoutube />
                  </button>
                  <button className="footer__socialLink rounded-5 d-flex justify-content-center align-items-center">
                    <BsMedium />
                  </button>
                </div>
              </Col>
              <Col
                md={4}
                sm={6}
                className="d-flex justify-content-start justify-content-sm-end align-items-start"
              >
                <div>
                  <p className="m-0 hebrew_strong reviewCard__name  text-uppercase ">
                    Quick Links
                  </p>
                  <ul className="footer__links mt-3">
                    <li className="hebrew_font mb-2">
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="home"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="hebrew_font mb-2">
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="about"
                      >
                        About
                      </Link>
                    </li>
                    <li className="hebrew_font mb-2">
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="features"
                      >
                        Features
                      </Link>
                    </li>
                    <li className="hebrew_font mb-2">
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="blog"
                      >
                        Blog
                      </Link>
                    </li>
                    <li className="hebrew_font mb-2">
                      <Link
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="reachUs"
                      >
                        Reach Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col md={2} xs={6} className='d-flex justify-content-center align-items-start'>
                <div>
            <p className='m-0 hebrew_strong reviewCard__name  text-uppercase '>Product</p>
            <ul className='footer__links mt-3'>
                <li className='hebrew_font mb-2'><NavLink>New</NavLink></li>
                <li className='hebrew_font mb-2'><NavLink>Upcoming</NavLink></li>
                <li className='hebrew_font mb-2'><NavLink>Lorem</NavLink></li>
                <li className='hebrew_font mb-2'><NavLink>Lorem</NavLink></li>
                <li className='hebrew_font mb-2'><NavLink>Lorem</NavLink></li>
            </ul>
            </div>
            </Col> */}
              {/* <Col md={4} className="mt-4 mt-md-0">
                <div className="d-flex justify-content-start align-items-center gap-4">
                  <img
                    src={Images.newsletter}
                    className="img-fluid footer__newsletterImg"
                    alt=""
                  />
                  <div>
                    <p className="m-0 hebrew_strong footer__newsletterBold">
                      Save time, save money!
                    </p>
                    <p className="m-0 hebrew_font footer__newsletterDesc mt-2">
                      Sign up and we'll send the best deals to you
                    </p>
                  </div>
                </div>
                <div className="footer__subscribe d-flex justify-content-start align-items-center gap-2 mt-4">
                  <input
                    type="email"
                    placeholder="Your Email"
                    className="footer__subsMail hebrew_font rounded-1 flex-grow-1"
                  />
                  <button className="primary_grad_btn hebrew_strong rounded-1">
                    Subscribe
                  </button>
                </div>
              </Col> */}
            </Row>
          </div>
         
        </div>
      </Container>
    </>
  );
};

export default Footer;
