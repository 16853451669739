import React, { useState } from 'react'
import { Images } from './Images'
import PairModal from '../Modals/PairModal';
import ScheduleModal from '../Modals/ScheduleModal';

const OpportunityCard = (props) => {
    const [showPair, setShowPair] = useState(false);

  const handleClosePair = () => setShowPair(false);
  const handleShowPair = () => setShowPair(true);
//   const [showSchedule, setShowSchedule] = useState(false);

//   const handleCloseSchedule = () => setShowSchedule(false);
//   const handleShowSchedule = () => setShowSchedule(true);
  return (
    <>
    <div className='opportunityCard position-relative' onClick={handleShowPair}>
        <div className='opportunityCard__topSec d-flex justify-content-between align-items-center pt-4 px-4 pb-2'>
            <div className='opCard__topSecLeft'>
                <img src={props.data.leftImg} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg opCard__CoinName mt-4 text-center text-uppercase'>{props.data.leftCoinName}</p>
            </div>
            <p className='m-0 opCard__transact'><i class="fa-solid fa-right-left"/></p>
            <div className='opCard__topSecLeft'>
                <img src={props.data.rightImg} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg opCard__CoinName mt-4 text-center text-uppercase'>{props.data.rightCoinName}</p>
            </div>
        </div>
        <div className='opCard__profilHolder'>
            <p className='m-0 opCard__profit hebrew_font text-center py-1'>Profit <span className='ms-3'>{props.data.profilt}%</span></p>
        </div>

    </div>

    {/* modals */}
    <PairModal show={showPair} handleClose={handleClosePair} handleShow={handleShowPair} />

    
    {/* <ScheduleModal show={showSchedule} handleClose={handleCloseSchedule} handleShow={handleShowSchedule} /> */}
     
    {/* end of modals */}
    </>
  )
}

export default OpportunityCard