import React from 'react'

const ReviewCard = (props) => {
  return (
    <>
    <div className='reviewCard d-flex flex-column justify-content-center align-items-center pt-4 pb-3 px-5 rounded-3 position-relative me-4'>
      
        <p className='m-0 hebrew_font reviewCard__desc text-center'>{props.data.cont}</p>
<div className='reviewCard__blueLiner mt-3'></div>
<p className='m-0 hebrew_strong reviewCard__name text-center text-uppercase mt-2'>{props.data.name}</p>
<div className='reviewCard__starHolder mt-2 text-center'>
<i class="fa-solid fa-star"/>
<i class="fa-solid fa-star"/>
<i class="fa-solid fa-star"/>
<i class="fa-solid fa-star"/>
<i class="fa-regular fa-star"/>
</div>
    </div>
    </>
  )
}

export default ReviewCard