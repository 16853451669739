import React, { useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import LandingHeader from '../Components/LandingHeader'
import { Images } from '../Components/Images'
import Select from "react-select";
import InnerFooter from '../Components/InnerFooter';

const Dashboard = () => {

    const currencyRef = useRef();
    const [closer, setCloser] = useState();
    const options = [
        {
          value: "aave",
          label: "aave",
          img: Images.aave,
        },
        {
          value: "weth",
          label: "weth",
          img:  Images.weth,
        },
        {
          value: "polygon",
          label: "Polygon",
          img:  Images.polygon,
        },
        {
          value: "link",
          label: "Link",
          img:  Images.link,
        },
        {
          value: "rndr",
          label: "rndr",
          img:  Images.rndr,
        },
      ];
    const [selectedCurrency, setSelectedCurrency] = useState({
        value: "bnb",
        label: "bnb",
        img: Images.tron,
      });

      const customCurrencyRenderer = ({ label, data, value }) => (
        <div
          className="market_select_component"
          onClick={() => {
            setSelectedCurrency({ label, value, img: data.img });
            currencyRef.current.blur();
            setCloser(!closer);
          }}
        >
          {console.log("sdhguisd", label, data, value)}
          <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
          {label}
        </div>
      );

      const styles = {
        option: (styles, { isFocused, isSelected, isHovered }) => ({
          ...styles,
          color: "#fff",
          background: isFocused
            ? "#00CABA"
            : isSelected
            ? "#00CABA"
            : isHovered
            ? "red"
            : "#00CABA",
    
          zIndex: 1,
          cursor: "pointer",
          fontSize: "13px",
        }),
    
        option: (styles, { isFocused, isSelected, isHovered }) => {
          // const color = chroma(data.color);
    
          return {
            ...styles,
            backgroundColor: isHovered
              ? "#16EBC3"
              : isSelected
              ? "#16EBC3"
              : isFocused
              ? "#16EBC3"
              : "#151515",
            cursor: "pointer",
            color: isHovered
              ? "#000"
              : isSelected
              ? "#000"
              : isFocused
              ? "#000"
              : "#fff",
            fontSize: "13px",
          };
        },
        indicatorSeparator: (styles) => ({ display: "none" }),
        valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "40px",
          paddingLeft: "40px",
        //   border: "1px solid #e3e0e0",
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "transperant",
          // border: "1px solid rgba(34, 34, 34, 0.32)",
          borderRadius: 5,
          fontSize: "13px",
          color: "#fff",
        }),
        control: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "40px",
        //   border: "1px solid #00CABA",
          borderRadius: 5,
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "transperant",
          // backgroundColor: "#fff",
          border: "none",
          outline: "none",
          boxShadow: "none",
          color: "#fff",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "40px",
          position: "absolute",
          right: 0,
          top: 0,
          color: "#fff",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "#fff",
        }),
        menuList: (base) => ({
          ...base,
          // kill the white space on first and last option
          padding: 0,
          borderRadius:'5px'
        }),
      };
  return (
    <>
    <Container fluid className='p-0 position-relative common_bg'>
    <img
              src={Images.radiantBlur}
              className="home__dashboardLeftBlur img-fluid"
              alt=""
            />
            <img
              src={Images.radiantBlur}
              className="home__dashboardRightBlur img-fluid"
              alt=""
            />
   
        <LandingHeader/>
        <Container className='home__indexAligner innerpages_ui'>
        <div className='plans__holder'>
        <h4 className='m-0 hebrew_bold opportunity__title'>Dashboard</h4>
            <p className='plans__titleHint m-0 hebrew_font position-relative mt-2'>Create Your Account to Gain Access to Our Arbitrage and Career Earning Opportunities</p>

            <Row className='mt-4 h-100'>
                <Col lg={8}>
                <div className='dashboard__blueBox p-4 rounded-2 h-100'>
                <h4 className='m-0 hebrew_bold dashboard__midGrad'>Trade 11 Stats</h4>

                <Row className='mt-4'>
                    <Col sm={4} className='mb-3 mb-sm-0'>
                        <div className='dashboard__pinkBox rounded-2 py-3'>
                            <p className='m-0 hebrew_strong dashboard__boxTitle text-center dashboard__blueTitle'>No.of Trade</p>
                            <h6 className='m-0 hebrew_bold dashboard__tradeValue text-center'>516</h6>
                        </div>
                    </Col>
                    <Col sm={4} className='mb-3 mb-sm-0'>
                        <div className='dashboard__greenBox rounded-2 py-3'>
                            <p className='m-0 hebrew_strong dashboard__boxTitle text-center dashboard__greenTitle'>Total Trade Volume</p>
                            <p className='m-0 hebrew_bold dashboard__dollarValue text-center'>$38,51625</p>
                        </div>
                    </Col>
                    <Col sm={4} className='mb-3 mb-sm-0'>
                        <div className='dashboard__skyBox rounded-2 py-3'>
                            <p className='m-0 hebrew_strong dashboard__boxTitle text-center dashboard__blueTitle'>Total Earned</p>
                            <p className='m-0 hebrew_bold dashboard__dollarValue text-center'>$487.62</p>
                        </div>
                    </Col>
                </Row>
                <div>
                <Row className='mt-4 h-100'>
                <Col md={6} className='mb-4 mb-md-0'>
                        <div className='dashboard__blackBox h-100 rounded-2 p-3'>
                        <h6 className='m-0 hebrew_bold dashboard__semiGrad text-uppercase mb-4'>USER STATS</h6>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Total Profit:</p>
                            <p className='dashboard__label m-0 hebrew_font'>$ 0</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Total Volume:</p>
                            <p className='dashboard__label m-0 hebrew_font'>$ 0</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Total Trade limit:</p>
                            <p className='dashboard__label dashboard__greenLabel m-0 hebrew_font'> 0</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center'>
                            <p className='dashboard__label m-0 hebrew_font'>Limit left:</p>
                            <p className='dashboard__label m-0 hebrew_font'>$ 0</p>
                        </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='dashboard__blackBox h-100 rounded-2 p-3'>
                        <h6 className='m-0 hebrew_bold dashboard__semiGrad text-uppercase mb-4'>Flash Loan</h6>
                        <p className='dashboard__label m-0 hebrew_font'>Select Network</p>
                        <div className='d-flex justify-content-center align-items-center gap-5 mt-3'>
                        <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                            <img src={Images.bin} className='img-fluid opCard__coinImg' alt='' />
                            <p className='m-0 hebrew_font dashboard__smallText text-center'>Binance</p>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                            <img src={Images.polygon} className='img-fluid opCard__coinImg' alt='' />
                            <p className='m-0 hebrew_font dashboard__smallText text-center'>Polygon</p>
                            </div>
                           
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mt-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Total Flash Loan Granted</p>
                            <p className='dashboard__label m-0 hebrew_font'>$ 52000</p>
                        </div>
                        </div>
                    </Col>
                </Row>
                </div>
                </div>
                </Col>

                <Col lg={4}>
                    <div className='dashboard__blueBox p-4 rounded-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='m-0 hebrew_bold dashboard__smallGrad text-uppercase'>MARKET OPPORTUNITIES</h6>
                    <p className='dashboard__label m-0 hebrew_font'>View all</p>
                    </div>
                    <div className='dashboard__dualCardFlex d-flex justify-content-start align-items-center gap-5 mt-3 pb-2'>
                    <div className='dashboard__singleCard'>
                    <div className='d-flex justify-content-start align-items-center gap-4'>
            <div className='opCard__topSecLeft'>
                <img src={Images.tron} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg opCard__CoinName mt-4 text-center text-uppercase'>Tron</p>
            </div>
            <p className='m-0 dashboard__transactAct'><i class="fa-solid fa-plus"/></p>
            <div className='opCard__topSecLeft'>
                <img src={Images.aave} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg opCard__CoinName mt-4 text-center text-uppercase'>aave</p>
            </div>
        </div>
        <p className='m-0 dashboard__profit hebrew_strong text-center py-1'>Profit <span className='ms-1'>36.5%</span></p>
        </div>

        <div className='dashboard__singleCard'>
                    <div className='d-flex justify-content-start align-items-center gap-4'>
            <div className='opCard__topSecLeft'>
                <img src={Images.rndr} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg opCard__CoinName mt-4 text-center text-uppercase'>rndr</p>
            </div>
            <p className='m-0 opCard__transact'><i class="fa-solid fa-plus"/></p>
            <div className='opCard__topSecLeft'>
                <img src={Images.link} className='img-fluid opCard__coinImg' alt='' />
                <p className='m-0 herbrew_reg opCard__CoinName mt-4 text-center text-uppercase'>link</p>
            </div>
        </div>
        <p className='m-0 dashboard__profit hebrew_strong text-center py-1'>Profit <span className='ms-1'>36.5%</span></p>
        </div>
                    </div>
                    </div>

                    <div className='dashboard__blueBox p-4 rounded-2 mt-1'>
                    <h6 className='m-0 hebrew_bold dashboard__smallGrad text-uppercase mb-3'>ARBITRAGE DETAILS</h6>

                    <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-2'>
                            <p className='dashboard__label m-0 hebrew_font'>DEX:</p>
                            <p className='dashboard__label m-0 hebrew_font'>-</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-2'>
                            <p className='dashboard__label m-0 hebrew_font'>Swap Tokens</p>
                            <p className='dashboard__label m-0 hebrew_font'>-</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-2'>
                            <p className='dashboard__label m-0 hebrew_font'>Profit Percent(%):</p>
                            <p className='dashboard__label m-0 hebrew_font'>0</p>
                        </div>                       
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-2'>
                            <p className='dashboard__label m-0 hebrew_font'>Profit Amount:  </p>
                            <p className='dashboard__label m-0 hebrew_font'>0.00000</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-2'>
                            <p className='dashboard__label m-0 hebrew_font'>Payout Token:</p>
                            <p className='dashboard__label m-0 hebrew_font'>0</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-2'>
                            <p className='dashboard__label m-0 hebrew_font'>Profit Amount (USD): </p>
                            <p className='dashboard__label m-0 hebrew_font'>$ 0.00000</p>
                        </div>
                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center'>
                            <p className='dashboard__label m-0 hebrew_font'>Platform Fee:</p>
                            <p className='dashboard__label m-0 hebrew_font'>0%</p>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className='dashboard__blueBox p-4 rounded-2 mt-4'>
            <h6 className='m-0 hebrew_bold dashboard__smallGrad text-uppercase'>borrow</h6>
            <p className='m-0 hebrew_font dashboard__balance mt-2'>Balance: $ 0.0000</p>

            <div className='dashboard__blackBox rounded-2 px-3'>
                <Row className='justify-content-between align-items-center mb-3'>
                    <Col sm={3}>
                    <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency.img}
                    className="img-fluid market_coinname_img dashboard__selectedImg"
                  />
                  <Select
                    className=""
                    defaultValue={selectedCurrency}
                    onChange={selectedCurrency}
                    options={options}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
                    </Col>
                    <Col sm={4}>
                    <p className='m-0 hebrew_font dashboard__balance text-end'>Change Quantity</p>
                    </Col>
                </Row>
            
            </div>

            <p className='dashboard__label m-0 hebrew_font text-end'>USD Price : 101.3000 USD</p>
<div className='mt-4'>
            <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Slippage</p>
                            <p className='dashboard__label m-0 hebrew_font'>2.5%</p>
                        </div>

                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Borrowed Asset:</p>
                            <p className='dashboard__label m-0 hebrew_font dashboard__greenLabel'>Tron</p>
                        </div>

                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Amount:</p>
                            <p className='dashboard__label m-0 hebrew_font dashboard__greenLabel'>100 Tron</p>
                        </div>

                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Lender Fee (0.05 %):</p>
                            <p className='dashboard__label m-0 hebrew_font dashboard__greenLabel'>0.0500 Tron</p>
                        </div>

                        <div className='dashboard__singleFlex d-flex justify-content-between align-items-center mb-4'>
                            <p className='dashboard__label m-0 hebrew_font'>Payback Amount:</p>
                            <p className='dashboard__label m-0 hebrew_font dashboard__greenLabel'>100.05 Tron</p>
                        </div>
                        </div>

                        <div className='text-center mt-4'>
                            <button className='primary_grad_btn primary_gradBtnWidth rounded-5'>Confirm</button>
                        </div>
            </div>
            </div>

            
            </Container>
            <InnerFooter/>
    </Container>
    </>
  )
}

export default Dashboard