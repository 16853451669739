import './App.css';
import { Route, Routes } from 'react-router-dom';
import './Assets/css/Style.css'
import Landing from './Screens/Landing';
import Plans from './Screens/Plans';
import Opportunities from './Screens/Opportunities';
import Dashboard from './Screens/Dashboard';
import TicketManagement from './Screens/TicketManagement';
import TransactionHistory from './Screens/TransactionHistory';
import SupportTicket from './Screens/SupportTicket';
import TermsOfServices from './Screens/TermsOfServices';
import PrivacyPolicy from './Screens/PrivacyPolicy';

function App() {
  return (
   <>
   <Routes>
    <Route path='/' element={<Landing/>} />
    <Route path='/plans' element={<Plans/>} />
    <Route path='/opportunities' element={<Opportunities/>} />
    <Route path='/dashboard' element={<Dashboard/>} />
    <Route path='/ticketManagement' element={<TicketManagement/>} />
    <Route path='/transactionHistory' element={<TransactionHistory/>} />
    <Route path='/supportTicket' element={<SupportTicket/>} />
    <Route path='/termsOfServices' element={<TermsOfServices/>} />
    <Route path='/privacyPolicy' element={<PrivacyPolicy/>} />
   </Routes>
   </>
  );
}

export default App;
