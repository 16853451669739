import React, { useEffect, useState } from "react";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import { Images } from "./Images";
import { NavLink } from "react-router-dom";
import ConnectWallet from "../Modals/ConnectWallet";

const LandingHeader = () => {
  // connect wallet state
  const [showWallet, setShowWallet] = useState(false);

  const handleCloseWallet = () => setShowWallet(false);
  const handleShowWallet = () => setShowWallet(true);

  // other states
  const [isScollred, setIsScrolled] = useState(false);

  // canva state
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container
        fluid
        className={`px-0 py-3 ${isScollred ? "header__scrolled" : "header"}`}
      >
        <Container className="">
          <Row className="align-items-center justify-content-between">
            <Col md={2} xs={6}>
              <NavLink to="/">
                <img
                  src={Images.logo}
                  className="img-fluid landing__logo"
                  alt=""
                />
              </NavLink>
            </Col>
            <Col md={7} xs={0} className="d-none d-lg-block">
              <div className="header__links_holder ">
                <ul className="d-flex justify-content-center align-items-center gap-5 m-0">
                  <NavLink to="/dashboard">
                    <li>Dapp</li>
                  </NavLink>
                  <NavLink to="/transactionHistory">
                    <li>Transaction History</li>
                  </NavLink>
                  <NavLink to="/opportunities">
                    <li>Market Opportunities</li>
                  </NavLink>
                  <NavLink to="/plans">
                    <li>Subscriptions</li>
                  </NavLink>
                  <NavLink to="/supportTicket">
                    <li>Support</li>
                  </NavLink>
                </ul>
              </div>
            </Col>
            <Col md={3} xs={6} className="d-flex justify-content-end">
              <button className="primary_grad_btn header__loginBtn hebrew_bold text-uppercase rounded-3" onClick={handleShowWallet}>
                Connect Wallet
              </button>
              <button
                className="primary_grad_btn hebrew_bold rounded-3 ms-2 d-lg-none"
                onClick={handleShow}
              >
                <i class="fa-solid fa-bars" />
              </button>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* offcanvas */}
      <Offcanvas
        show={show}
        placement="end"
        className="header__offcanva"
        onHide={handleClose}
        backdrop="static"
      >
        <Offcanvas.Body>
          <div className="header__canvaTop d-flex justify-content-between align-items-center">
            <img src={Images.logo} className="img-fluid landing__logo" alt="" />
            <button className="header__canvaCloser" onClick={handleClose}>
              <i class="fa-solid fa-xmark fa-beat-fade" />
            </button>
          </div>

          <div className="header__links_holder ">
            <ul className="d-flex mt-4 flex-column justify-content-center align-items-start gap-3 m-0">
              <NavLink to="/dashboard">
                <li>Dapp</li>
              </NavLink>
              <NavLink to="/transactionHistory">
                <li>Transaction History</li>
              </NavLink>
              <NavLink to="/opportunities">
                <li>Market Opportunities</li>
              </NavLink>
              <NavLink to="/plans">
                <li>Subscriptions</li>
              </NavLink>
              <NavLink to="/supportTicket">
                <li>Support</li>
              </NavLink>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* end of offcanvas */}

      {/* modals */}

    <ConnectWallet show={showWallet} handleClose={handleCloseWallet} />
      {/* end of modals */}
    </>
  );
};

export default LandingHeader;
