import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import LandingHeader from '../Components/LandingHeader'
import {  NavLink } from 'react-router-dom'
import InnerFooter from '../Components/InnerFooter'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scroll(0,0)
    },[])
  return (
    <>
<Container fluid className='p-0 position-relative common_bg'>
        <LandingHeader/>
        <Container className='home__indexAligner'>
        <div className='plans__holder'>
        <h4 className='m-0 hebrew_bold opportunity__title'>Privacy Policy</h4>
           

            <div className='dashboard__blueBox p-4 pt-0 rounded-2 mt-4'>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative pt-4'>Our greetings and welcome to Trade11, <NavLink target='_blank' to='https://trade11.io'>https://trade11.io</NavLink>, a website-hosted user interface managed and operated by Trade11 Corp LLC (the “Interface”). Trade11 (hereafter also “We”) offers online software as a service (SaaS) through the website <NavLink target='_blank' to='https://trade11.io'>https://trade11.io</NavLink> (hereafter – “Website”). Our software (hereafter – “Software”) enables you to trade and invest in cryptocurrencies by means of an automatic crypto trader bot – of which (solely) you control and configure the settings. To provide you with our Services, we might need (and sometimes obliged by the law) to collect your personal data. We strictly follow industry best practices and adhere to the rules set forth in the General Data Protection Regulation, OPPA, CAN-SPAM and COPPA.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>1. What types of personal information do we collect?</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>While providing our services we collect:</p>
            <ul className='terms__ulList m-0 mt-2 ms-4'>
                <li className='hebrew_font terms__list plans__titleHint mt-2'>IP address</li>
                <li className='hebrew_font terms__list plans__titleHint mt-2'>Your Username</li>
                <li className='hebrew_font terms__list plans__titleHint mt-2'>Your Email address</li>
            </ul>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>2. Where do we store and process data of EU citizens?</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>We store and process your data on the territory of the EU. Please note that we identify your jurisdiction based on your IP address.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>3. Who is the data controller?</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'><NavLink target='_blank' to='https://trade11.io'>https://trade11.io</NavLink></p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>4. Who is the data processor?</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>Trade11 Corp LLC</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>5. Can I modify my personal data?</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>If you are a registered user of our Services, you can do so in your account settings. You may correct, delete, or modify the personal information you provided to us and associated with your account.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>6. Do I have a right to access my data?</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>In the case you are a EU citizen and want to exercise your rights enshrined in art. 15 of GDPR, please write us at <NavLink target='_blank' to='hello@trade11.io'>hello@trade11.io</NavLink></p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>7. Law and Harm</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>Notwithstanding anything to the contrary in this Privacy Policy, we may preserve or disclose your information if we believe that it is reasonably necessary to comply with a law, regulation, legal process, or governmental request; to protect the safety of any person; to address fraud, security or technical issues; or to protect our or our users’ rights or property. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to a government’s request for disclosure of your information.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>8. Right to be forgotten</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>If you want to close your account and delete all information that would allow anyone to identify you, please write to us at <NavLink target='_blank' to='hello@trade11.io'>hello@trade11.io</NavLink>. Please note that such a request will also terminate your Trade11 account permanently. No refund policy allowed as per terms as assets / funds stored on your account only.</p>

            <h4 className='m-0 hebrew_bold dashboard__midGrad privacy__midGrad pt-4'>9. Privacy Policy</h4>
            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>The Privacy Policy describes the ways we collect, use, store and disclose your personal information. You agree to the collection, use, storage, and disclosure of your data in accordance with the privacy policy.</p>

            <p className='plans__titleHint mt-2 m-0 hebrew_font position-relative'>If you are located in the EEA, UK, or Switzerland, you may take advantage of Your Online Choices. This service allows you to select tracking preferences for most of the advertising tools. As such, it is recommended that you make use of this resource in addition to the information provided in this document.</p>
            </div>
            </div>
            </Container>
            <InnerFooter/>
            </Container>
    </>
  )
}

export default PrivacyPolicy