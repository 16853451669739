import React from 'react'
import {Link} from "react-router-dom";

const BlogCard = (props) => {
    console.log(props,"safdsafas");
  return (
    <>
    <div className='home__blogCard rounded-4 pt-3 px-3 pb-4 d-flex flex-column'>
    <img src={props.data.img} alt='' className='img-fluid home__blogCardImg' />
    <h6 className='m-0 hebrew_font home__blogCardTitle my-3'>{props.data.title}</h6>
    <p className='hebrew_font home__blogCardHint'>{props.data.hint}</p>
    <Link to="/" className='green_link'>Read More<i class="fa fa-long-arrow-right ms-3"></i></Link>
    </div>
    </>
  )
}

export default BlogCard