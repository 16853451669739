import React from 'react'
import { Modal } from 'react-bootstrap'
import { Images } from '../Components/Images'

const ScheduleModal = ({show,handleClose,handleShow}) => {

  return (
    <>
    <Modal centered  size='sm'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body className='cmn__modal'>
          <p className='text-end m-0 modal__closer' onClick={handleClose}><i class="fa-solid fa-xmark"/></p>

        <div className='modal__singleInpFlex d-flex justify-content-start align-items-center gap-3 my-4'>
        <p className='m-0 hebrew_font ticketManage__question cmnModal_inpLabel'>From :</p>
        <div className='position-relative flex-grow-1'>
        <img src={Images.aave} className='img-fluid cmnModal__inpCoinImg' alt='' />
        <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1' value='Aave' />
        </div>
        </div>

        <div className='modal__singleInpFlex d-flex justify-content-start align-items-center gap-3 mb-4'>
        <p className='m-0 hebrew_font ticketManage__question cmnModal_inpLabel'>To :</p>
        <div className='position-relative flex-grow-1'>
            {/* <p className='modal__inputAbs m-0 hebrew_font'>%</p> */}
            <img src={Images.tron} className='img-fluid cmnModal__inpCoinImg' alt='' />
        <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1' value='Tron' />
        </div>
        </div>

        <div className='modal__singleInpFlex'>
        <p className='m-0 hebrew_font ticketManage__question'>Loan Amount:</p>
        <div className='position-relative'>
        <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1 mt-2' />
        </div>
        </div>

        <div className='modal__singleInpFlex mt-4'>
        <p className='m-0 hebrew_font ticketManage__question'>Price Difference:</p>
        <div className='position-relative'>
        <input type='text' className='ticketManage__input hebrew_font w-100 rounded-1 mt-2' />
        </div>
        </div>

        <div className='cmnModal__dualBtns d-flex justify-content-center mt-4'>
            <button className='primary_grad_btn rounded-5 primary_gradBtnWidth hebrew_strong' onClick={handleClose}>Confirm</button>
            
        </div>
        </Modal.Body>
        
      </Modal>

 
    </>
  )
}

export default ScheduleModal