export const Images = {
    logo : require('../Assets/Images/logo.svg').default,
    bannerImg : require('../Assets/Images/banner.svg').default,
    fantom : require('../Assets/Images/fantom.svg').default,
    binance : require('../Assets/Images/binance.svg').default,
    bitfinex : require('../Assets/Images/bitfinex.svg').default,
    paraswap : require('../Assets/Images/paraswap.svg').default,
    globe : require('../Assets/Images/globe.svg').default,
    orangeGraph : require('../Assets/Images/orange_graph.svg').default,
    pinkGraph : require('../Assets/Images/pink_graph.svg').default,
    blueGraph : require('../Assets/Images/blue_graph.svg').default,
    network : require('../Assets/Images/network.svg').default,
    accordInc : require('../Assets/Images/accord_plus.svg').default,
    accordDec : require('../Assets/Images/accord_minus.svg').default,
    greenBubble : require('../Assets/Images/green_bubble.svg').default,
    radiantBubble : require('../Assets/Images/radiant_bubble.svg').default,
    radiantBlur : require('../Assets/Images/radiant_blur.png'),
    greenBlur : require('../Assets/Images/green_blur.png'),
    middleBlur : require('../Assets/Images/middle_blur.png'),
    triangle_left : require('../Assets/Images/triangle_left.svg').default,
    triangle_right : require('../Assets/Images/triangle_right.svg').default,
    blog1 : require('../Assets/Images/blog1.png'),
    blog2 : require('../Assets/Images/blog2.png'),
    blog3 : require('../Assets/Images/blog3.png'),
    blog4 : require('../Assets/Images/blog4.png'),
    swiperIcon : require('../Assets/Images/swiper_right.svg').default,
    middleBlueBlue : require('../Assets/Images/middleBlueBlur.png'),
    plansRightBlur : require('../Assets/Images/plansrightblur.png'),
    aave : require('../Assets/Images/aave.svg').default,
    matic : require('../Assets/Images/matic.svg').default,
    link : require('../Assets/Images/link.svg').default,
    rndr : require('../Assets/Images/rndr.svg').default,
    usdc : require('../Assets/Images/usdc.svg').default,
    weth : require('../Assets/Images/weth.svg').default,
    tron : require('../Assets/Images/tron.svg').default,
    iq : require('../Assets/Images/iq.svg').default,
    shield : require('../Assets/Images/shield.svg').default,
    seamless : require('../Assets/Images/seamless.svg').default,
    technical : require('../Assets/Images/technical.svg').default,
    dedicated : require('../Assets/Images/dedicated.svg').default,
    visionary : require('../Assets/Images/visionary.svg').default,
    newsletter : require('../Assets/Images/newsletter.svg').default,
    polygon : require('../Assets/Images/polygon.svg').default,
    bin : require('../Assets/Images/bin.svg').default,
    metamask : require('../Assets/Images/metamask.svg').default,
    walletConnect : require('../Assets/Images/walletconnect.svg').default,
    trustWallet : require('../Assets/Images/trustwallet.svg').default,


}