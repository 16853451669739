import React from 'react'
import { Container } from 'react-bootstrap'
import LandingHeader from '../Components/LandingHeader'
import ReactDatatable from '@ashvin27/react-datatable';
import { NavLink } from 'react-router-dom';
import { Images } from '../Components/Images';
import InnerFooter from '../Components/InnerFooter';

const SupportTicket = () => {
    const state = {
        records: [
          {
            'ticNo': <p className='exchange_table_values m-0 text-center'>1</p>,
            "walletAddress": <p className='exchange_table_values m-0 text-center'>9qhf934h9334</p>,
            "severity": <p className='exchange_table_values m-0 text-center'>server-1</p>,
            "dateofRise": <p className='exchange_table_values m-0 text-center'>21-Jan-2024</p>,
            "status" : <p className='exchange_table_values m-0 text-center'>Completed</p>,
            "dateofClosed" : <p className='exchange_table_values m-0 text-center'>24-Jan-2024</p>,
            "details" : <p className='exchange_table_values m-0 text-center'>Updating</p>,   
          },
          {
            'ticNo': <p className='exchange_table_values m-0 text-center'>1</p>,
            "walletAddress": <p className='exchange_table_values m-0 text-center'>9qhf934h9334</p>,
            "severity": <p className='exchange_table_values m-0 text-center'>server-1</p>,
            "dateofRise": <p className='exchange_table_values m-0 text-center'>21-Jan-2024</p>,
            "status" : <p className='exchange_table_values m-0 text-center'>Completed</p>,
            "dateofClosed" : <p className='exchange_table_values m-0 text-center'>24-Jan-2024</p>,
            "details" : <p className='exchange_table_values m-0 text-center'>Updating</p>,   
          },
          
        ]
    }
    
    const columns = [
      {
        key: "ticNo",
        text: "Tic.No",
        className: "ticNo",
        align: "center",
        sortable: false,
    },
        {
            key: "walletAddress",
            text: "Wallet Address",
            className: "walletAddress",
            align: "center",
            sortable: false,
            
        },
        {
            key: "severity",
            text: "severity",
            className: "severity",
            align: "center",
            sortable: false,
            
        },
        {
            key: "dateofRise",
            text: "Date Of Rise",
            className: "dateofRise",
            align: "center",
            sortable: false,
            
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            align: "center",
            sortable: false,
            
        },
        {
            key: "dateofClosed",
            text: "Date of Closed",
            className: "dateofClosed",
            align: "center",
            sortable: false,
            
        },
        {
            key: "details",
            text: "Details",
            className: "details",
            align: "center",
            sortable: false,
            
        },
        
    ];
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Data found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: false,
    };

    const extraButtons =[
        {
            className:"btn btn-primary buttons-pdf",
            title:"Export TEst",
            children:[
                <span>
                <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick:(event)=>{
                console.log(event);
            },
        },
        {
            className:"btn btn-primary buttons-pdf",
            title:"Export TEst",
            children:[
                <span>
                <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick:(event)=>{
                console.log(event);
            },
            onDoubleClick:(event)=>{
                console.log("doubleClick")
            }
        },
    ]
  return (
    <>
    <Container fluid className='p-0 position-relative common_bg'>
    <img
              src={Images.radiantBlur}
              className="home__dashboardLeftBlur img-fluid"
              alt=""
            />
            <img
              src={Images.radiantBlur}
              className="home__dashboardRightBlur img-fluid"
              alt=""
            />
    <LandingHeader/>
    <Container className='home__indexAligner innerpages_ui'>
    <div className='plans__holder'>
    <h4 className='m-0 hebrew_bold opportunity__title'>Support Tickets</h4>
    {/* <input type='text' className='transacHistory__input hebrew_font w-100 rounded-1 mt-2' placeholder='Search with TXN Hash'/> */}
    <div className='dashboard__blueBox rounded-2 mt-5'>
        <div className='d-flex justify-content-center'>
    <h4 className='m-0 hebrew_bold dashboard__midGrad  py-3'>Raise a Ticket</h4>
    </div>
    <div className='transacHistory__tableHolder'>
    <ReactDatatable 
                    config={config}
                    records={state.records}
                    columns={columns}
                />
    </div>

    <div className='text-center mt-5 pb-5'>
        <NavLink to='/ticketManagement'>
    <button className='secondary_btn cmnModal_secondaryBtn rounded-5 primary_gradBtnWidth hebrew_strong' data='Create New Ticket'></button>
    </NavLink>
    </div>
    </div>
    </div>
    </Container>
    <InnerFooter/>
   </Container>
    </>
  )
}

export default SupportTicket